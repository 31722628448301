<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="withdrawFormChild">
    <div class="form-list-inner clearfix">
      <ul class="form-list-three-columns clearfix">
        <li>
          <el-form-item :label="$t('withdraw.Chain')" prop="chain">
            <el-select v-model="form.chain" data-testid="chain">
              <el-option
                v-for="option in formChainOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.USDTEmail')" prop="cryptoWalletAddress">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.cryptoWalletAddress"
              auto-complete="off"
              data-testid="cryptoWalletAddress"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
      <div class="alert-info">
        {{ $t('withdraw.cryptoAlert') }}
      </div>
      <br />
    </div>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      formChainOptions: [
        { text: 'TRC20', value: 'trc20' },
        { text: 'ERC20', value: 'erc20' }
      ],
      form: {
        cryptoWalletAddress: '',
        importantNotes: '',
        chain: 'trc20'
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.usdt'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const chainType = this.form.chain;
              if (chainType === 'trc20' && !/^T/.test(value)) {
                callback(new Error(this.$t('withdraw.formErrorMsg.walletFormatIncorrect', { type: 'TRC20' })));
              } else if (chainType === 'erc20' && !/^0x/.test(value)) {
                callback(new Error(this.$t('withdraw.formErrorMsg.walletFormatIncorrect', { type: 'ERC20' })));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        chain: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.chain'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              this.$refs.withdrawFormChild.validateField('cryptoWalletAddress');
              callback();
            },
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
