<template>
  <el-form :model="form" label-position="top" :rules="rulesAustralia" ref="withdrawFormAustralia">
    <div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.AustralianBankName')" prop="bankName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankName"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BSB')" prop="bsbCode">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bsbCode"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bsbCode"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.SwiftAud')" prop="swift">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.swift"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="swift"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.beneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="form.isRememberInfo" data-testid="checkbox">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';

export default {
  props: {
    withdrawalType: Number,
    chooseWithdrawalType: Object,
    selectedCardInfo: Object,
    transferCountryString: String
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      transferCountry: null,
      form: {
        country: 1,
        bankName: '',
        accountNumber: '',
        bsbCode: '',
        swift: '',
        beneficiaryName: '',
        importantNotes: '',
        userPaymentInfoId: ''
      },
      rulesAustralia: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        bsbCode: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bsb'),
            trigger: 'blur'
          }
        ],
        swift: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.swift'),
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneficiaryNameReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        console.log(value);
        if (value && value.id !== -1) {
          this.form = {
            country: this.form.country,
            bankName: this.selectedCardInfo.bankName,
            accountNumber: this.selectedCardInfo.accountNumber,
            bsbCode: this.selectedCardInfo.bsbCode,
            swift: this.selectedCardInfo.swift,
            beneficiaryName: this.selectedCardInfo.beneficiaryName,
            importantNotes: this.selectedCardInfo.importantNotes,
            userPaymentInfoId: this.selectedCardInfo.id,
            isRememberInfo: this.selectedCardInfo.id ? '' : this.form.isRememberInfo,
            userPaymentInfoId: this.selectedCardInfo.id ? this.selectedCardInfo.id : ''
          };
          this.isdisabled = true;
        } else {
          this.isdisabled = false;
          this.form.userPaymentInfoId = '';
        }
      }
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormAustralia.validate();
    }
  }
};
</script>
