<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="withdrawFormChild">
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.ETHEmail')" prop="cryptoWalletAddress">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.cryptoWalletAddress"
              auto-complete="off"
              data-testid="cryptoWalletAddress"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
      <div class="alert-info">
        {{ $t('withdraw.cryptoAlert') }}
      </div>
    </div>
  </el-form>
</template>

<script>
import ethereum_address from 'ethereum-address';

export default {
  data() {
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.eth')));
      } else if (!ethereum_address.isAddress(value)) {
        callback(new Error(this.$t('withdraw.formErrorMsg.ethFormat')));
      } else callback();
    };
    return {
      form: {
        cryptoWalletAddress: '',
        importantNotes: ''
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
